import React from "react"
import logo from "../images/dark-logo.png"
import {
  contactDesignation,
  contactName,
  phoneNumber,
  contactEmail,
  facebook,
  twitter,
  instagram,
  phoneNumberLabel
} from "../config"

const Footer = () => (
  <footer className="section footer" id="footer-dark">
    <div className="container">
      <div className="row">
        <div className="col-md-3 mb-5">
          <div className="footer-widget">
            <img src={logo} alt="CAP"/>
          </div>
        </div>

        <div className="col-md-3 mb-2">
          <div className="footer-widget">
            <h3 className="mb-4">About</h3>
            <p>
              24/7 Ride 2 Fly offers clients throughout Tuscaloosa and the surrounding area quality private
              transportation services. Great service begins with great people and real experience which is why we put so
              much into choosing the best people to join our team.
            </p>
          </div>
        </div>

        <div className="col-md-3">
          <div className="footer-widget">
            <h3 className="mb-4">Reach Us</h3>
            <p>
              <i className="ion-email"/>
              &nbsp;
              <a href={`mailto:${contactEmail}`}>
                {" "}
                {contactName} {contactDesignation}
              </a>
            </p>
            <p>
              <i className="ion-ios-telephone"/>
              &nbsp;&nbsp;
              <a href={`tel: ${phoneNumber}`}>
                {phoneNumberLabel} <br/>
              </a>
            </p>
          </div>
          <div className="footer-widget">
            <h3 className="mt-5 mb-4">For General Queries</h3>
            <p>
              <a href={`mailto:${contactEmail}`}> {contactEmail}</a>
            </p>
          </div>
        </div>

        <div className="col-md-3">
          <div className="footer-widget follow">
            <h3 className="mb-4">Follow Us</h3>
            <ul>
              <li>
                <a href={facebook} target="_blank">
                  <i className="ion-social-facebook"/>
                </a>
              </li>
              <li>
                <a href={twitter} target="_blank">
                  <i className="ion-social-twitter"/>
                </a>
              </li>
              <li>
                <a href={instagram} target="_blank">
                  <i className="ion-social-instagram"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row pt-5 border-top">
        <div className="col-md-12 text-center">
          <p className="footer-text">
            Copyright 2021 © <a href="#" target={"_blank"}>24/7 Ride 2 Fly.</a> All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
