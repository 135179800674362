import React, { Component } from "react"
import logo from "../images/logo.png"
import scrollToElement from "scroll-to-element"
import { Link } from "gatsby"

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      scrolled: false
    }
  }

  componentDidMount () {
    window.addEventListener("scroll", this.onScroll.bind(this))
    if (window.location.hash && window.location.hash !== "") {
      scrollToElement(window.location.hash)
    }
    this.listener = window.addEventListener(
      "hashchange",
      this.scrollToWindowHash
    )
  }

  componentWillUnmount () {
    window.removeEventListener("hashchange", this.scrollToWindowHash)
  }

  scrollToWindowHash () {
    if (window.location.hash !== "") {
      scrollToElement(window.location.hash)
    }
  }

  scrollToMenu (id) {
    scrollToElement(id)
    document.querySelector(".navbar-toggler").click()
  }

  onScroll () {
    if (window.pageYOffset > 200) {
      this.setState({
        scrolled: true
      })
    } else {
      this.setState({
        scrolled: false
      })
    }
  }

  render () {
    const { scrolled } = this.state
    const { isHomePage } = this.props
    return (
      <header className="header-bar">
        <nav
          className={
            "navbar navbar-expand-lg navbar-light transparent-white fixed-top main-nav2 " +
            (scrolled ? "top-nav-collapse" : "")
          }
        >
          <div className="container">
            <div className="nav-box">
              <Link className="navbar-brand" to="/">
                <img src={logo} alt="logo" className="img-fluid"/>
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="ion-navicon"></i>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/book">
                    Book Now
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
