import React from "react"
import Meta from "./meta"
import Header from "./header"
import Footer from "./footer"
import "../styles/main.scss"
import "../scss/style.scss"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"
import { metaTags } from "../config"
import { useLocation } from "@reach/router"
export default ({ children, ...props }) => {
  const location = useLocation()
  const pathname = location.pathname.replace(/\/(?=[^\/]*$)/, "")

  return (
    <div className={"main-container"}>
      <Meta metaTags={location ? metaTags[pathname] : []} />
      <Header />
      {children}
      <Helmet>
        <script
          src="https://www.googletagmanager.com/gtag/js?id=UA-189810392-1"
          async
          type="text/javascript"
        />
        <script src={withPrefix("third-party.js")} type="text/javascript" />
      </Helmet>
      <Footer />
    </div>
  )
}
