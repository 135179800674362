import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import done from "../images/done.png"
import error from "../images/error.png"

const BookPage = () => {
  const [message, setMessage] = React.useState(null)
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search)
    if (query.get("status") === "success") {
      setMessage({
        text: "Your ride has been booked successfully",
        className: "success",
        image: done,
      })
    } else {
      setMessage({
        text:
          "There was an error while booking your ride. In case your payment has been deducted, it will be refunded in 1-2 days.",
        className: "error",
        image: error,
      })
    }
  }, [])
  return (
    <Layout>
      <section className="section about-page booking-status">
        <div className="container">
          {message && (
            <div className={`status ${message.className}`} role="alert">
              <img src={message.image} />
              <p>{message.text}</p>
              <Link className="btn btn-yellow" to="/book">
                Book Again
              </Link>
            </div>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default BookPage
