import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

const IndexPage = () => {
  return (
    <Layout>
      <div className="landing">
        <div className="splash">
          <div className="overlay" />
          <div className="content">
            <div>
              {/*<img src={car} className="car-image"/>*/}
              <p className="title">
                Private Airport Transportation Services For Tuscaloosa and
                Birmingham
                <br /> Starting from <span className="highlight">$50!</span>
              </p>
              {/*<div className="call">
                <a><span className="icon"><i className="ion-android-call"></i></span></a>
                <a>+91-987654321</a>
              </div>*/}
              <Link className="btn btn-yellow" to="/book">
                Book Your Ride
              </Link>
            </div>
          </div>
        </div>
        <div className="section features">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="feature">
                  <span className="icon">
                    <i className="ion-model-s"></i>
                  </span>
                  <div className="details">
                    <p className="name">Lowest Price</p>
                    <p className="text">
                      We don’t surge prices. Fair and transparent upfront
                      pricing. All fares include up to 2 people. 45 minutes wait
                      time maximum at the airport. One way fares are below:
                    </p>
                    <p className="text bold">$50 Tuscaloosa - Birmingham</p>
                    <p className="text bold">$130 Tuscaloosa - Atlanta</p>
                    <p className="text bold">$75 Birmingham - Atlanta</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="feature">
                  <span className="icon">
                    <i className="ion-android-time"></i>
                  </span>
                  <div className="details">
                    <p className="name">Available 24/7</p>
                    <p className="text">
                      Our professional drivers run around the clock to get you
                      to and from the airport safely. Due to our rates being so
                      low and the high demand of our services, we recommend that
                      you schedule a pick-up in advance so that you know your
                      ride will be there when you're ready to go. We use the
                      latest models vehicles, including some luxury vehicles in
                      our fleet.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="feature">
                  <span className="icon">
                    <i className="ion-android-bulb"></i>
                  </span>
                  <div className="details">
                    <p className="name">Safety First</p>
                    <p className="text">
                      Our vehicles are thoroughly cleaned and inspected before
                      each pickup. Our drivers wear masks, and we will provide
                      you with one when we pick you up. We ask that you keep
                      your mark on during your entire ride with us. Our drivers
                      are all professionally trained and have thorough
                      background checks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage
