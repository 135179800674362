import React from "react"
import { Helmet } from "react-helmet"
const Meta = ({ metaTags }) => (
  <Helmet>
    <title>
      24/7 Ride 2 Fly | Quality Private Transportation Services at Lowest Price
    </title>
    <meta
      name="google-site-verification"
      content="mPewH7Xbx2zWxtZFN-eA0En6ObElnZZyQU9BkaL4CQc"
    />
    {(metaTags || []).map(({ name, content }, index) => {
      return <meta key={index} name={name} content={content} />
    })}
  </Helmet>
)

export default Meta
