export const phoneNumberLabel = "(866) 999-1655"
export const phoneNumber = "(866) 999-1655"
export const contactName = "Ride2Fly"
export const contactEmail = "support@247ride2fly.com"
export const contactDesignation = "(Team)"
export const facebook =
  "https://www.facebook.com/247-ride-2-fly-100839602055801"
export const twitter = "https://twitter.com/247ride2fly"
export const instagram = "https://www.Instagram.com/247ride2fly"
export const emailToken =
  typeof window !== "undefined" && window.location.hostname == "localhost"
    ? "d667b379-5044-45a1-808a-77c2e4b288d3"
    : "144f3282-16e1-4985-8973-8a7dee8b624c"
export const formEmailTo = "idsingh43@gmail.com"
export const formEmailFrom = "no-reply@laxaar.com"
export const stripeKey = ""
export const locations = ["Birmingham, AL", "Atlanta, GA", "Tuscaloosa, AL"]
export const airports = ["Tuscaloosa, AL", "Birmingham, AL", "Atlanta, GA"]
export const prices = {
  [airports[0] + "-" + locations[0]]: 50,
  [locations[0] + "-" + airports[0]]: 50,
  [airports[0] + "-" + locations[1]]: 120,
  [locations[1] + "-" + airports[0]]: 120,
  [airports[1] + "-" + locations[1]]: 70,
  [locations[1] + "-" + airports[1]]: 70,
}
export const pricePerPassenger = 25
export const metaTags = {
  "/": [
    {
      name: "title",
      content:
        "Airport Transportation Services Tuscaloosa, Birmingham and Atlanta",
    },
    {
      name: "description",
      content:
        "We offer clients throughout Tuscaloosa and the surrounding area quality private transportation services. Private transportation services, Birmingham airport transportation services, Airport Car Service Tuscaloosa and Private Airport Transportation.",
    },
  ],
  "/book": [
    {
      name: "title",
      content: "Book Your Airport Transfer Service | 24/7 Ride 2 Fly",
    },
    {
      name: "description",
      content:
        "Book Your Airport Transfer Service with 24/7 Ride 2 Fly in Tuscaloosa, Birmingham and Atlanta. For more Info- (866) 999-1655 or Email us -support@247ride2fly.com",
    },
  ],
  "/about": [
    {
      name: "title",
      content: "Best Airport Transportation Service Company | 24/7 Ride 2 Fly",
    },
    {
      name: "description",
      content:
        "24/7 Ride 2 Fly offers clients throughout Tuscaloosa, Birmingham, and Atlanta areas quality private transportation services.  Best Airport Transportation Service Company. Book Your Ride -(866) 999-1655.",
    },
  ],
  "/contact": [
    {
      name: "title",
      content: "Contact us | Best Airport Transportation Service at Birmingham",
    },
    {
      name: "description",
      content:
        "Best Airport Transportation Service at Birmingham. Our office - 24/7 Ride 2 Fly PO BOX 71703 Tuscaloosa, AL 35407. Call us (866) 999-1655, Email us support@247ride2fly.com.",
    },
  ],
}
