import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import cab1 from "../images/bmw.jpg"

const AboutPage = () => (
  <Layout>
    <section id="about" className="section general-page">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8  text-center">
            <div className="page-heading">
              <h2 className="display-4">About Us</h2>
              <ul className="list-unstyled list-inline">
                <li className="list-inline-item">
                  <Link to={"/"}>Home </Link>
                </li>
                <li className="list-inline-item">
                  <a>/</a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="text-muted">
                    About Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="section information">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="image">
              <img src={cab1} alt={"About"} />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="details">
              <div className="heading">
                <div className="title">
                  <p>About Us</p>
                </div>
              </div>
              <div className="content description">
                <p>
                  24/7 Ride 2 Fly offers clients throughout Tuscaloosa,
                  Birmingham, and Atlanta areas quality private transportation
                  services. Great service begins with great people and real
                  experience which is why we put so much into choosing the best
                  people to join our team.
                </p>
              </div>
              <div className="action">
                <Link className="btn btn-yellow" to="/book">
                  Book Your Ride
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage
