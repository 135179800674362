// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-booking-status-js": () => import("./../../../src/pages/booking-status.js" /* webpackChunkName: "component---src-pages-booking-status-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-1-js": () => import("./../../../src/pages/page1.js" /* webpackChunkName: "component---src-pages-page-1-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */)
}

