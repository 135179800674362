import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { phoneNumber, contactEmail, phoneNumberLabel } from "../config"

const PolicyPage = () => (
  <Layout>
    <section id="about" className="section general-page">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8  text-center">
            <div className="page-heading">
              <h2 className="display-4">Terms and Policy</h2>
              <ul className="list-unstyled list-inline">
                <li className="list-inline-item">
                  <Link to={"/"}>Home </Link>
                </li>
                <li className="list-inline-item">
                  <a>/</a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="text-muted">
                    Terms and Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about-page" className="section about-page policy">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h6 className="subtitle">
                No Show or Leaving Without Notice Policy
              </h6>
            </div>
            <div className="content">
              <div className="list">
                <p className="label">Rights and Liabilities:</p>
                <ul>
                  <li><p>24/7 RIDE2FLY reserves the right to use a preferred vendor if its own vehicles are
                    unavailable.</p></li>
                  <li><p>24/7 RIDE2FLY reserves the right to add extra charges for repair or cleaning whenever such
                    services are required as a result of customer use or abuse.</p></li>
                  <li><p>24/7 RIDE2FLY is not liable for circumstances beyond its control, such as weather conditions,
                    road conditions, or vehicle breakdowns. </p></li>
                  <li><p>24/7 RIDE2FLY assumes no responsibility for lost or damaged articles left in or nearby our
                    vehicles. </p></li>
                </ul>
              </div>
              <div className="list">
                <p className="label">Wait Times</p>
                <ul>
                  <li><p>For airport pickups, 24/7 RIDE2FLY allows a grace period of 30 minutes from the reported
                    arrival time of domestic flights and a grace period of 45 minutes from the reported arrival time of
                    international flights.</p></li>
                  <li><p>For airport departures, 24/7 RIDE2FLY allows a grace period of 15 minutes from the scheduled
                    pickup time. After 15 minutes, waiting time is considered retroactive to the scheduled departure
                    time. Waiting time charges are assessed hourly.</p></li>
                  <li><p>For both arrivals and departures, a waiting charge of $12.50 will be assessed for every 15
                    minutes a chauffeur is kept waiting after the grace period in question has expired.</p></li>
                  <li><p>Passenger-requested stops or deviations from route during a flat-rate trip will be charged 24/7
                    RIDE2FLY prevailing hourly rate.</p></li>
                </ul>
              </div>
              <div className="list">
                <p className="label">No-Show Fee</p>
                <ul>
                  <li><p>A no-show fee equal to trip cost plus applicable waiting time will be charged if the passenger
                    fails to appear at the designated location. To avoid this charge, passengers should not leave
                    designated locations without first notifying 24/7 RIDE2FLY.</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row terms">
          <div className="col-lg-12">
            <div className="section-heading">
              <h6 className="subtitle">
                Cancellation Policy
              </h6>
            </div>
            <div className="content">
              <div className="list">
                <p>**Cancellations made 24 hours prior to the pick-up time will be fully refunded. Cancellation up to 12
                  hours before the pick-up time will be subject to a $20 administrative fee. Cancellations made within
                  12 hours prior to the pick-up time will be charged 100% of the total fare. </p>
                <p>* If you need to make changes to this reservation please contact us at 1-866-999-1655.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PolicyPage
