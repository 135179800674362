import React from "react";
import Layout from "../components/layout";

const IndexPage = () => {
  return (
    <Layout>
      <h1>Page 1</h1>
    </Layout>
  );
};
export default IndexPage;
