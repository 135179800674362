import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { loadStripe } from "@stripe/stripe-js"
import { airports, locations, pricePerPassenger, prices } from "../config"

import scrollToElement from "scroll-to-element"
//
const stripePromise = loadStripe(
  "pk_live_51IF8rGH6IHWGs6tiBK7z6xGjd1EdELFIhZeZzFMLMnLogjxQXNjwK1DKi3orum2efTrOdHaf5PFu6yjSHyVD9Smi001QdDHRNn"
)

function pad(n, width, z) {
  z = z || "0"
  n = n + ""
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

const BookPage = () => {
  const [message, setMessage] = React.useState(null)
  const formRef = React.useRef(null)
  let date = new Date()
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
  const currentTime = pad(date.getHours(), 2) + ":" + pad(date.getMinutes(), 2)
  const currentDate = date.toISOString().split("T")[0]
  const [formValues, setFormValues] = React.useState({})
  const updateFormValues = () => {
    for (let element of formRef.current.elements) {
      formValues[element.name] = element.value
    }
    const newFormValues = {
      ...formValues,
    }
    setFormValues(newFormValues)
    return newFormValues
  }
  const onChange = e => {
    updateFormValues()
  }
  const updateMessage = obj => {
    setMessage(obj)
    setTimeout(() => {
      setMessage(null)
    }, 5000)
  }
  useEffect(() => {
    updateFormValues()
  }, [])
  const passengers = parseInt(formValues.Adults) + parseInt(formValues.Children)
  const onSubmit = async e => {
    e.preventDefault()
    let formValues = updateFormValues()
    try {
      if (passengers < 1) {
        updateMessage({
          text: "Please select atleast one passenger",
          className: "alert-danger",
        })
        scrollToElement(document.querySelector("#about-page"))
        return
      }
      const price = getPrice()
      if (!totalPrice) {
        updateMessage({
          text: "We don't operate on this Route yet",
          className: "alert-danger",
        })
        scrollToElement(document.querySelector("#about-page"))
        return
      }
      window.startSpinning(formRef.current)
      const stripe = await stripePromise
      let session = await fetch(`/api/process-payment`, {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
          "Content-Type": "application/json",
        },
      })
      const status = session.status
      session = await session.json()
      if ([200, 201].indexOf(status) === -1) {
        throw {
          status,
          ...session,
        }
      }
      await stripe.redirectToCheckout({
        sessionId: session.id,
      })
    } catch (error) {
      console.debug("Error while sending email", { error })
      updateMessage({
        text:
          error.status === 409
            ? "Sorry, Bookings are full at this Flight Time, Please try booking at a later time."
            : "There was an error while booking your ride.",
        className: "alert-danger",
      })
    }
    scrollToElement(document.querySelector("#about-page"))

    window.stopSpinning(formRef.current)
  }
  const getRoute = () => {
    // return formValues["Booking Type"] === "From Airport"
    //   ? formValues["Airport"] + "-" + (formValues["To"] || formValues["From"])
    //   : (formValues["To"] || formValues["From"]) + "-" + formValues["Airport"]
    return (
      formValues["Pickup City/Airport"] +
      "-" +
      formValues["Destination City/Airport"]
    )
  }
  const getPrice = () => {
    let price = prices[getRoute()]
    if (price && passengers > 2) {
      price = price + (passengers - 2) * pricePerPassenger
    }
    return price
  }
  let totalPrice = passengers > 0 ? getPrice() : null

  return (
    <Layout>
      <section id="about" className="section general-page">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8  text-center">
              <div className="page-heading">
                <h2 className="display-4">Book Your Ride</h2>
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <Link to={"/"}>Home </Link>
                  </li>
                  <li className="list-inline-item">
                    <a>/</a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#" className="text-muted">
                      Book Now
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-page" className="section about-page book">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                <h6 className="subtitle">Book Your Ride</h6>
              </div>
              <div className="form">
                {message && (
                  <div className={`alert ${message.className}`} role="alert">
                    {message.text}
                  </div>
                )}
                <form onSubmit={onSubmit} ref={formRef}>
                  <div className="inline">
                    <div className="row">
                      {/* <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Source</label>
                          <select
                            className="form-control"
                            name="Booking Type"
                            required
                            onChange={onChange}
                          >
                            <option>To Airport</option>
                            <option>From Airport</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Name *</label>
                          <input
                            className="form-control"
                            placeholder="Name"
                            required
                            name="Name"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Email *</label>
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Email"
                            required
                            name="Email"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Phone *</label>
                          <input
                            className="form-control"
                            placeholder="Phone"
                            type="phone"
                            required
                            name="Phone"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Pickup/Destination Address *
                          </label>
                          <input
                            className="form-control"
                            placeholder="Pickup/Destination Address"
                            type="text"
                            required
                            name="Pickup/Destination Address"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inline">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Pickup City/Airport
                          </label>
                          <select
                            className="form-control"
                            name="Pickup City/Airport"
                            required
                            onChange={onChange}
                          >
                            {airports.map((item, index) => (
                              <option key={index}>{item}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            {/* {formValues["Booking Type"] === "From Airport"
                              ? "Destination City"
                              : "Pickup City"} */}
                            Destination City/Airport
                          </label>
                          <select
                            className="form-control"
                            // name={
                            //   formValues["Booking Type"] === "From Airport"
                            //     ? "To"
                            //     : "From"
                            // }
                            name="Destination City/Airport"
                            required
                            onChange={onChange}
                          >
                            {locations
                              .filter(value => {
                                return (
                                  value !== formValues["Pickup City/Airport"]
                                )
                              })
                              .map((item, index) => (
                                <option key={index}>{item}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label>Adults *</label>
                          <select
                            className="form-control"
                            name="Adults"
                            required
                            onChange={onChange}
                          >
                            {Array(21)
                              .fill(1)
                              .map((item, index) => {
                                return <option key={index}>{index}</option>
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label>Children *</label>
                          <select
                            className="form-control"
                            name="Children"
                            required
                            onChange={onChange}
                          >
                            {Array(21)
                              .fill(1)
                              .map((item, index) => {
                                return <option key={index}>{index}</option>
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inline">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Flight Date
                          </label>
                          <div className="datepicker">
                            <input
                              name="Flight Date"
                              className="form-control"
                              type="date"
                              onChange={onChange}
                              min={currentDate}
                              defaultValue={currentDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Flight Time
                          </label>
                          <div className="datepicker">
                            <input
                              type="time"
                              name="Flight Time"
                              className="form-control"
                              onChange={onChange}
                              min={
                                formValues["Flight Date"] === currentDate
                                  ? currentTime
                                  : undefined
                              }
                              defaultValue={currentTime}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Flight Type
                          </label>
                          <select
                            className="form-control"
                            name="Flight Type"
                            onChange={onChange}
                          >
                            <option>Domestic</option>
                            <option>International</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="terms">
                    <p>
                      By booking you are agreeing to our{" "}
                      <Link to="/policy">Terms and Policy</Link>
                    </p>
                  </div>
                  <div className="action">
                    <button type="submit" className="btn btn-yellow">
                      Book {totalPrice ? `${totalPrice}$` : ""}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BookPage
